<template>
  <b-card no-body class="card-revenue-budget">
    <b-row class="mx-0">
      <b-col md="8" class="revenue-report-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            {{ $t('Revenue Report') }}
          </h4>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span
                class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer"
              />
              <span>{{ $t('Earnings') }}</span>
            </div>
          </div>
        </div>

        <!-- chart -->
        <vue-apex-charts
          id="revenue-report-chart"
          :options="revenueReport.chartOptions"
          :series="revenueReport.series"
          type="bar"
          height="230"
        />
      </b-col>

      <b-col md="4" class="budget-wrapper">
        <b-dropdown
          :text="String(selectedYear)"
          size="sm"
          class="budget-dropdown"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="year in years"
            :key="year"
            @click="updateChart(year)"
          >
            {{ year }}
          </b-dropdown-item>
        </b-dropdown>

        <h3>{{ $t('Total') }}:</h3>
        <h2 class="mb-5">
          <strong>{{ revenueReport.total }}</strong>
        </h2>

        <b-button
          :to="{ name: 'orders-list' }"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
        >
          {{ $t('Orders') }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import axios from '@/libs/axios'
import {
  BCard,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from 'bootstrap-vue'
import { formatPrice } from '@core/utils/filter'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  setup() {
    return {
      formatPrice,
    }
  },
  data() {
    return {
      selectedYear: 0,
      revenueReport: {
        total: 0,
        series: [],
        chartOptions: {
          chart: {
            stacked: true,
            type: 'bar',
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: true },
            },
          },
          xaxis: {
            categories: [
              this.$t('Jan'),
              this.$t('Feb'),
              this.$t('Mar'),
              this.$t('Apr'),
              this.$t('May'),
              this.$t('Jun'),
              this.$t('Jul'),
              this.$t('Aug'),
              this.$t('Sep'),
              this.$t('Oct'),
              this.$t('Nov'),
              this.$t('Dec'),
            ],
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Inter',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.primary, $themeColors.warning],
          plotOptions: {
            bar: {
              columnWidth: '17%',
              endingShape: 'rounded',
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Inter',
              },
            },
          },
        },
      },
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
    years() {
      return [this.currentYear, this.currentYear - 1, this.currentYear - 2]
    },
  },
  created() {
    this.selectedYear = this.currentYear
    this.updateChart(this.currentYear)
  },
  methods: {
    async updateChart(year) {
      this.selectedYear = year
      const params = {
        params: {
          year: year,
        },
      }
      const response = await axios.get('statistics/year-revenue-totals', params)
      this.revenueReport.total = formatPrice(response.data.total)
      const response2 = await axios.get(
        'statistics/yearmonth-revenue-totals',
        params
      )
      this.revenueReport.series = [
        {
          name: this.$t('Earnings'),
          data: response2.data,
        },
      ]
    },
  },
}
</script>
