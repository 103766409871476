<template>
  <b-card v-if="data" class="card-browser-states">
    <b-card-title class="mb-1">
      {{ $t('Categories Table') }}
    </b-card-title>
    <b-card-body>
      <div
        v-for="productCat in data"
        :key="productCat.name"
        class="browser-states"
      >
        <span class="align-self-center my-auto">
          {{ productCat.name }}
        </span>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1">{{
            productCat.usage
          }}</span>
          <vue-apex-charts
            type="radialBar"
            height="30"
            width="30"
            :options="radialBar.options"
            :series="productCat.series"
          />
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      radialBar: {
        series: [],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary, $themeColors.warning],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '25%',
              },
              track: {
                background: '#ebe9f1',
                strokeWidth: '70%',
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
    }
  },
}
</script>
