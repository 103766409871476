<template>
  <b-card v-if="tableData" no-body class="card-company-table">
    <b-table
      :items="tableData"
      :fields="columns"
      responsive
      class="tableHead mb-0"
      caption-top
    >
    <template #table-caption>
      <caption class="product-table-title">
        {{ $t('Popular platform products') }}
      </caption>
      </template>
    

      <!-- company -->
      <template #cell(product)="data">
        <ecommerce-product-table-cell :data="data.item" />
      </template>
      <template #cell(supplier)="data">
        <b-link
          :to="{ path: 'browse-products' }"
          @click="setProductCode(data.item.code)"
          >{{ data.value }}</b-link
        >
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BCard, BTable, BLink } from 'bootstrap-vue'
import { mapMutations } from 'vuex'
import EcommerceProductTableCell from './EcommerceProductTableCell.vue'

export default {
  components: {
    BCard,
    BTable,
    BLink,
    EcommerceProductTableCell,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    columns() {
      return [
        { key: 'product', label: this.$i18n.t('ProductTable Product') },
        { key: 'category', label: this.$i18n.t('ProductTable Category') },
        { key: 'clicks', label: this.$i18n.t('ProductTable Clicks') },
        { key: 'supplier', label: this.$i18n.t('ProductTable Supplier') },
      ]
    },
  },
  methods: {
    ...mapMutations(['setProductCode']),
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

.tableHead ::v-deep thead tr th {
  text-transform: none;
}

.product-table-title {
  text-align: center !important;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  
}
// .product-table-title[data-v-3ff6c00c] {
//   font-weight: 600;
//   text-transform: uppercase;
// }

</style>
