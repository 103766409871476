<template>
  <div class="d-flex align-items-center">
    <b-avatar rounded size="32" variant="light-company">
      <b-img :src="data.featuredImg" alt="avatar img"
    /></b-avatar>
    <div>
      <div class="font-weight-bolder">
        <a :href="path" target="_blank">
          {{ data.title }}
        </a>
      </div>
      <div class="font-small-2 text-muted">
        {{ data.code }}
      </div>
    </div>
  </div>
</template>
<script>
import { BAvatar, BImg } from 'bootstrap-vue'
export default {
  components: {
    BAvatar,
    BImg,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    path(){
        return (process.env.VUE_APP_SHOP_URL+'product/'+this.data.eshopSlug)
    }
  },
}
</script>
<style></style>
