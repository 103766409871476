<template>
  <b-card v-if="data" class="card-transaction" no-body>
    <b-card-header>
      <b-card-title>{{ title }}</b-card-title>
    </b-card-header>

    <b-card-body
      v-if="
        title !== $t('my_product_clicks') && title !== $t('users_of_my_product')
      "
    >
      <div v-for="item in data" :key="item.title" class="transaction-item">
        <div class="d-flex align-items-center">
          <b-avatar
            v-if="item.featuredImg"
            rounded
            size="32"
            variant="light-company"
          >
            <b-img :src="item.featuredImg" :alt="item.code"
          /></b-avatar>
          <div class="mx-1">
            <div class="font-weight-bolder">
              <h6 class="transaction-title">
                {{ item.title }}
              </h6>
            </div>
            <div v-if="item.code" class="font-small-2 text-muted">
              {{ item.code }}
            </div>
          </div>
        </div>
        <div v-if="item.value">
          {{ item.value }}
        </div>
        <div v-if="item.clicks">
          {{ item.clicks }}
        </div>
      </div>
    </b-card-body>
    <ecommerce-products-users
      v-if="
        title === $t('my_product_clicks') || title === $t('users_of_my_product')
      "
      :data="data"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BImg,
  BAvatar,
} from 'bootstrap-vue'
import EcommerceProductsUsers from './EcommerceProductsUsers.vue'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BImg,
    EcommerceProductsUsers,
    BAvatar,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },
}
</script>
