<template>
  <section id="dashboard-ecommerce">
    <div class="d-flex align-items-center justify-content-end mb-1">
      <label class="d-inline font-small-3 mr-1 mb-0 nowrap"
        >{{ $t('Select Date Range') }}:
      </label>
      <feather-icon icon="CalendarIcon" size="16" />
      <flat-pickr
        v-model="rangePicker"
        :config="{ mode: 'range', maxDate: new Date() }"
        class="form-control d-inline flat-picker bg-transparent border-0 shadow-none"
        placeholder="YYYY-MM-DD"
        @on-change="updateAllCharts"
      />
    </div>
    <b-row class="match-height">
      <b-col xl="2" md="3">
        <!-- to show total user visited count  -->
        <statistic-card-with-area-chart
          v-if="usersGained"
          :statistic="kFormatter(gaStatistics.users)"
          :statistic-title="$t('Users platform')"
          :chart-data="usersGained.series"
          icon="UsersIcon"
        />
      </b-col>
      <b-col xl="2" md="3">
        <statistic-card-with-area-chart
          v-if="storeViews"
          :statistic="kFormatter(storeViews.count)"
          :statistic-title="$t('Store Views Platform')"
          :chart-data="storeViews.series"
          icon="PackageIcon"
          color="warning"
        />
      </b-col>
      <b-col xl="8" md="6">
        <ecommerce-statistics
          :ladata="interactionStatistics2"
          :gadata="interactionStatistics1"
          :statistic-title="$t('Statistics')"
        />
        <!-- to show individual statistics for seller and supplier-->
        <ecommerce-statistics
          v-if="!isAdmin"
          :ladata="myInteractionStatistics2"
          :gadata="myInteractionStatistics1"
          :statistic-title="$t('My Statistics')"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12">
        <b-row v-if="!isSupplier && !isSeller" class="match-height">
          <b-col lg="6" md="6">
            <ecommerce-order-chart :data="myStoreViews" />
          </b-col>
          <b-col lg="6" md="6">
            <ecommerce-earnings-chart
              :data="gaStatistics.deviceUsers"
              :seller-stats-chart-title="$t('User Devices')"
              :label-fields="labelFieldUserDevices"
            />
          </b-col>
        </b-row>
        <b-row v-if="isSeller" class="match-height">
          <b-col lg="4" md="4">
            <ecommerce-order-chart :data="myStoreViews" />
          </b-col>
          <b-col lg="4" md="4">
            <ecommerce-earnings-chart
              :data="gaStatistics.deviceUsers"
              :seller-stats-chart-title="$t('User Devices')"
              :label-fields="labelFieldUserDevices"
              :chart-title="$t('Users')"
            />
          </b-col>
          <b-col lg="4" md="4">
            <ecommerce-earnings-chart
              :data="clickStats"
              :seller-stats-chart-title="$t('Click Stats')"
              :label-fields="labelFieldClickStats"
              :chart-title="$t('Clicks')"
            />
          </b-col>
        </b-row>
        <!-- to show avg time spent by users on Supplier products -->
        <b-row v-if="isSupplier" class="match-height">          
          <b-col lg="6" md="6">
            <ecommerce-profit-chart
              :data="sessionDuration"
              :avg="gaStatisticsIndividualStats.sessionAvg"
            />
          </b-col>
          <b-col lg="6" md="6">
            <ecommerce-earnings-chart
              :data="gaStatistics.deviceUsers"
              :seller-stats-chart-title="$t('User Devices')"
              :label-fields="labelFieldUserDevices"
              :chart-title="$t('Users')"
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- <b-col lg="8">
        <ecommerce-revenue-report />
      </b-col> -->
    </b-row>

    <b-row class="match-height">
      <b-col lg="12">
        <ecommerce-product-table v-if="!isSupplier" :table-data="products" />
      </b-col>
      <b-col lg="4" md="6">
        <ecommerce-plain-list :data="myproducts" :title="titleCheck" />
      </b-col>
      <b-col lg="4" md="6">
        <ecommerce-categories-chart :data="productCats" />
      </b-col>
      <b-col lg="4" md="6">
        <ecommerce-plain-list
          :data="gaStatistics.topFiveAreas"
          :title="$t('top five cities')"
        />
        <ecommerce-plain-list
          v-if="isSeller"
          :data="gaStatisticsIndividual"
          :title="$t('My Cities seller')"
        />
        <ecommerce-plain-list
          v-if="isSupplier"
          :data="gaStatisticsIndividual"
          :title="$t('My Cities supplier')"
        />
        <ecommerce-plain-list
          :data="gaStatisticsSellerUsersCity"
          :title="$t('Most Popular Areas')"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col>
        <b-card>
          Μια αλληλεπίδραση λαμβάνει χώρα όταν ένας πελάτης καλεί την επιχείρηση
          σας, επισκεφτείτε τα προϊόντα που διαθέτετε, στέλνει μήνυμα στην
          επιχείρηση σας, κάνει μια αγορά, ζητάει οδηγίες από το εταιρικό σας
          προφίλ και στέλνει ένα μήνυμα στην επιχείρηση σας.
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import axios from '@/libs/axios'
import moment from 'moment'
import { BRow, BCol, BCard } from 'bootstrap-vue'

import { kFormatter } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceProfitChart from './EcommerceProfitChart.vue'
import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceProductTable from './EcommerceProductTable.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import EcommercePlainList from './EcommercePlainList.vue'
import EcommerceCategoriesChart from './EcommerceCategoriesChart.vue'
import { isAdmin, isSeller, isSupplier } from '@/auth/utils'
import { title } from 'process'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    StatisticCardWithAreaChart,
    EcommerceStatistics,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceRevenueReport,
    EcommerceProductTable,
    EcommerceGoalOverview,
    EcommercePlainList,
    EcommerceCategoriesChart,
    flatPickr,
  },
  data() {
    return {
      products: [],
      productCats: [],
      myproducts: [],
      rangePicker: [
        moment(new Date()).subtract(7, 'day').format('YYYY-MM-DD'),
        new Date(),
      ],
      usersGained: {
        series: [
          {
            name: this.$t('Users'),
            data: [28, 40, 36, 52, 38, 60, 55],
          },
        ],
        count: 0,
      },
      storeViews: {
        series: [
          {
            name: this.$t('Store Views'),
            data: [10, 15, 8, 15, 7, 12, 8],
          },
        ],
        count: 0,
      },
      myStoreViews: {
        series: [
          {
            name: '2020',
            data: [45, 85, 65, 45, 65],
          },
        ],
        count: 0,
      },
      sessionDuration: {
        series: [
          {
            data: [0, 20, 5, 30, 15, 45],
          },
        ],
      },
      interactionStatistics1: [],
      interactionStatistics2: [],
      myInteractionStatistics1: [],
      myInteractionStatistics2: [],
      gaStatisticsIndividual: [],
      gaStatistics: {
        users: 0,
        sessionAvg: 0,
        deviceUsers: [0, 0, 0],
        topFiveAreas: [0, 0, 0, 0, 0],
      },
      gaStatisticsIndividualStats: {
        sessionAvg: 0,
      },
      gaStatisticsSellerUsersCity: [],
      clickStats: [0, 0, 0, 0],
      labelFieldUserDevices: ['desktop', 'mobile', 'tablet'],
      labelFieldClickStats: [
        this.$i18n.t('phone call'),
        this.$i18n.t('google map destination'),
        this.$i18n.t('create appointment'),
        this.$i18n.t('google map location'),
      ],
    }
  },
  computed: {
    isAdmin() {
      return isAdmin()
    },
    isSeller() {
      return isSeller()
    },
    isSupplier() {
      return isSupplier()
    },
    titleCheck() {
      if (!this.isSupplier) {
        return this.$t('my_product_clicks')
      } else {
        return this.$t('users_of_my_product')
      }
    },
  },
  created() {
    this.updateAllCharts(this.rangePicker)
  },
  methods: {
    async updateAllCharts(dates) {
      const params = {
        params: {
          startDate: moment(dates[0]).format('YYYY-MM-DD'),
          endDate: moment(dates[1]).format('YYYY-MM-DD'),
        },
      }
      this.updateUserStats(params)
      this.updateStoreStats(params)
      this.updateMyStoreStats(params)
      this.updateInteractionStats(params)
      this.updateGoogleAnalyticsStats(params)
      this.updateGoogleAnalyticsStatsIndividual(params)
      this.updateGoogleAnalyticsAvgTimeSpend(params)
      this.updateProducts(params)
      this.updateMyProducts(params)
      this.updateUserClicks(params)
      this.updatePerCategoryChart(params)
      this.updateMyInteractionStats(params)
      this.updateGoogleAnalyticsSellerUsersCity(params)
    },
    async updateInteractionStats(params) {
      const response = await axios.get('statistics/user-interaction', params)
      this.interactionStatistics2 = this.createInteractionStatsObj2(
        response.data
      )
    },
    //to get productViews and onlineSales of Seller and Supplier
    async updateMyInteractionStats(params) {
      const lparams = JSON.parse(JSON.stringify(params))
      lparams.params.useronly = true
      if (!this.isAdmin) {
        const response = await axios.get('statistics/user-interaction', lparams)
        this.myInteractionStatistics2 = this.createInteractionStatsObj2(
          response.data
        )
      }
    },
    async updateUserStats(params) {
      const response = await axios.get('statistics/users', params)
      this.usersGained.count = response.data.count
    },
    async updateStoreStats(params) {
      const response = await axios.get('statistics/stores', params)
      this.storeViews.count = response.data.count
    },
    async updateMyStoreStats(params) {
      const lparams = JSON.parse(JSON.stringify(params))
      lparams.params.useronly = true
      const response = await axios.get('statistics/stores', lparams)
      this.myStoreViews.count = response.data.count
    },
    async updateProducts(params) {
      if (!this.isSupplier) {
        const response = await axios.get('statistics/products', params)
        this.products = response.data
      }
    },
    async updateMyProducts(params) {
      const lparams = JSON.parse(JSON.stringify(params))
      lparams.params.useronly = true
      if (this.isSupplier) {
        const response = await axios.get(
          'statistics/products/supplier-users',
          lparams
        )
        this.myproducts = response.data
      } else {
        const response = await axios.get('statistics/products', lparams)
        this.myproducts = response.data
      }
    },
    async updateUserClicks(params) {
      const response = await axios.get('/statistics/user-clicks', params)
      this.clickStats = []
      this.clickStats = Object.values(response.data)
    },
    async updateGoogleAnalyticsStats(params) {
      const response = await axios.get('statistics/google-analytics', params)
      this.interactionStatistics1 = this.createInteractionStatsObj1(
        response.data
      )
      this.gaStatistics = response.data
      this.gaStatistics.sessionAvg = this.formatTime(
        this.gaStatistics.avgSessionDuration
      )
      this.gaStatistics.deviceUsers = []
      this.gaStatistics.deviceCategoryUsers.forEach((cat) => {
        this.gaStatistics.deviceUsers.push(parseInt(cat.value, 10))
      })
      //to get top 5 areas that users come from
      this.gaStatistics.topFiveAreas = this.gaStatistics.cityUsers.slice(0, 5)
    },

    //to get individual statistics for seller and supplier
    async updateGoogleAnalyticsStatsIndividual(params) {
      if (!this.isAdmin) {
        const response = await axios.get(
          'statistics/google-analytics/individual',
          params
        )
        if (response.data.cityUsers) {
          this.gaStatisticsIndividual = response.data.cityUsers.slice(0, 5)
        }
        //to store individual microsite views for each seller and supplier
        this.myInteractionStatistics1 = this.createIndividualMicrositeViewsObj(
          response.data
        )
        // //to store avg time spent by users on supplier products
        // if(!response.data.avgSessionDuration){
        //     this.gaStatisticsIndividualStats.sessionAvg = this.formatTime(0)
        // }
        // else{
        //     this.gaStatisticsIndividualStats.sessionAvg = this.formatTime(
        //     response.data.avgSessionDuration
        //     )
        // }
      }
    },
    async updateGoogleAnalyticsAvgTimeSpend(params) {
      if (this.isSupplier) {
        const response = await axios.get(
          'statistics/google-analytics/avg-time-spend',
          params
        )
        //to store avg time spent by users on supplier products
        if(!response.data.avgSessionDuration){
            this.gaStatisticsIndividualStats.sessionAvg = this.formatTime(0)
        }
        else{
            this.gaStatisticsIndividualStats.sessionAvg = this.formatTime(
            response.data.avgSessionDuration
            )
        }
      }
    },
    async updatePerCategoryChart(params) {
      const response = await axios.get('statistics/categories', params)
      const newData = []
      let total = 0
      response.data.forEach((item) => {
        total += item.clicks
      })
      response.data.forEach((item) => {
        const percent = parseFloat((item.clicks / total) * 100).toFixed(2)
        // eslint-disable-next-line prefer-template
        newData.push({
          name: item.title,
          usage: item.clicks,
          series: [percent],
        })
      })
      this.productCats = newData
    },
    //to show the most popular areas the users who visited the microsites are coming from
    async updateGoogleAnalyticsSellerUsersCity(params) {
      const response = await axios.get(
        'statistics/google-analytics/seller-users-city',
        params
      )
      this.gaStatisticsSellerUsersCity = response.data.cityUsers.slice(0, 5)
    },
    createInteractionStatsObj1(item) {
      return [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: item.newUsers,
          subtitle: 'New Users',
          customClass: 'mb-2 mb-xl-0',
        },
        //total views of microsites
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: item.pageviews,
          subtitle: 'Pageviews',
          customClass: 'mb-2 mb-xl-0',
        },
      ]
    },
    createInteractionStatsObj2(item) {
      return [
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: item.productViews,
          subtitle: 'Product Views',
          customClass: 'mb-2 mb-sm-0',
        },
        // {
        //   icon: 'DollarSignIcon',
        //   color: 'light-success',
        //   title: item.onlineSales,
        //   subtitle: 'Online Sales',
        //   customClass: '',
        // },
      ]
    },
    createIndividualMicrositeViewsObj(item) {
      const individualMicrositeViews = [
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: item.pageviews,
          subtitle: 'Pageviews',
          customClass: 'mb-2 mb-xl-0',
        },
      ]
      //to show default page views count as 0 if seller or supplier is not published
      {
        item.pageviews
          ? (individualMicrositeViews[0].title = item.pageviews)
          : (individualMicrositeViews[0].title = 0)
      }
      return individualMicrositeViews
    },
    formatTime(secs) {
      return moment.utc(secs * 1000).format('HH:mm:ss')
    },
    kFormatter,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
