<template> 
      <b-card-body class="products-users">
        <div v-for="item in data" :key="item.title" class="transaction-item">
          <div class="d-flex align-items-center">
            <b-avatar v-if="item.featuredImg" rounded size="32" variant="light-company">
              <b-img :src="item.featuredImg" :alt="item.code"
            /></b-avatar>
            <div class="mx-1">
              <div class="font-weight-bolder">
                <h6 class="transaction-title">
                  {{ item.title }}
                </h6>
              </div>
              <div v-if="item.code" class="font-small-2 text-muted">
                {{ item.code }}
              </div>
            </div>
          </div>
          <div v-if="item.value">
            {{ item.value }}
          </div>
          <div v-if="item.clicks">
            {{ item.clicks }}
          </div>
        </div>
      </b-card-body>
  </template>
  
  <script>
  import {
    BCardBody,
    BImg,
    BAvatar,
  } from 'bootstrap-vue'
  
  export default {
    components: {
      BCardBody,
      BImg,
      BAvatar,
    },
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
  }
</script>  
<style scoped>
.products-users{
    max-height: 740px;
    overflow-y:scroll;
}
.products-users::-webkit-scrollbar {
  display: none;
}

</style>
